import { Entity, Salon } from './'

export class Region extends Entity {
  thumbnail = ''

  constructor (salon: Salon | undefined = undefined) {
    super()
    if (salon !== undefined) {
      this.id = salon.remoteGrouping
      this.name = salon.remoteGrouping
      this.thumbnail = salon.thumbnail
    }
  }
}
