import { AxiosError } from 'axios'
import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'

export default firebase

export function firebaseMessage (error: AxiosError) : string {
  const code = (error.response && error.response.data.code) ? error.response.data.code : error.code
  const message = (error.response && error.response.data.messages) ? error.response.data.messages.join('<br>') : error.message
  switch (code) {
    case 'auth/invalid-email':
      return 'O e-mail informado é inválido'
    case 'auth/user-disabled':
    case 'auth/user-not-found':
      return 'Cliente não encontrado'
    case 'auth/wrong-password':
      return 'A senha informada é inválida'
    case 'auth/email-already-in-use':
      return 'O e-mail informado já está cadastrado. Favor efetuar login!\n\nCaso não lembre a sua senha, basta acessar a opção "esqueci minha senha" na tela de login'
    case 'auth/weak-password':
      return 'A senha informada é muito fraca'
    case 'sz/user-found':
      return 'O e-mail informado já está cadastrado. Verifique o seu e-mail e siga as instruções para redefinir a sua senha.'
    default:
      return message
  }
}
