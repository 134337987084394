import Vue from 'vue'
import Vuex from 'vuex'
import { AlertType, IState } from '../models'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    config: null,
    customer: null,
    firebaseToken: null,
    anonymousData: null,
    finishAnonymousSchedule: false,
    isAnonymousAccess: null,
    alert: { type: AlertType.success, message: null },
    loading: false,
    showLogin: false,
    ready: false,
    embedded: false,
    transparentBackground: false
  } as IState,
  mutations: {
    SET_READY (state, value) {
      state.ready = value
    },

    SET_LOADING (state, value) {
      state.loading = value
    },

    SET_CONFIG (state, config) {
      state.config = config
    },

    SET_EMBEDDED (state, embedded) {
      state.embedded = embedded
    },

    SET_TRANSPARENT_BACKGROUND (state, transparent) {
      state.transparentBackground = transparent
    },

    SET_CUSTOMER (state, customer) {
      state.customer = customer
    },

    SET_FIREBASE_TOKEN (state, token) {
      state.firebaseToken = token
    },

    SET_ANONYMOUS_DATA (state, data) {
      state.anonymousData = data
    },

    SET_FINISH_ANONYMOUS_SCHEDULE (state, value) {
      state.finishAnonymousSchedule = value
    },

    SET_SHOW_LOGIN (state, onSchedule: boolean) {
      const lateLogin = state.config && state.config.features.lateLogin
      state.showLogin = (state.firebaseToken == null && (onSchedule || !lateLogin))
    },

    SET_ALERT (state, alert) {
      state.alert = alert
    }
  },
  actions: {
    showLoading ({ commit }) {
      commit('SET_LOADING', true)
    },

    setAnonymousData ({ commit }, anonymousData) {
      commit('SET_ANONYMOUS_DATA', anonymousData)
    },

    setFinishAnonymousSchedule ({ commit }, finish) {
      commit('SET_FINISH_ANONYMOUS_SCHEDULE', finish)
    },

    hideLoading ({ commit }) {
      commit('SET_LOADING', false)
    },

    setReady ({ commit }, ready) {
      commit('SET_READY', ready)
    },

    setConfig ({ commit }, config) {
      commit('SET_CONFIG', config)
    },

    setEmbedded ({ commit }, embedded) {
      commit('SET_EMBEDDED', embedded)
    },

    setTransparentBackground ({ commit }, transparent) {
      commit('SET_TRANSPARENT_BACKGROUND', transparent)
    },

    setCustomer ({ commit }, customer) {
      commit('SET_CUSTOMER', customer)
      if (customer) {
        customer.getIdToken(true).then((token: string) => {
          commit('SET_FIREBASE_TOKEN', token)
          commit('SET_SHOW_LOGIN', false)
          commit('SET_LOADING', false)
          commit('SET_READY', true)
        })
      } else {
        commit('SET_FIREBASE_TOKEN', null)
        commit('SET_SHOW_LOGIN', false)
        commit('SET_LOADING', false)
        commit('SET_READY', true)
      }
    },

    checkNeedLogin ({ commit }, onSchedule = false) {
      commit('SET_SHOW_LOGIN', onSchedule)
    },

    setErrorAlert ({ commit }, message) {
      commit('SET_ALERT', { message: message, type: AlertType.error })
    },

    setSucessAlert ({ commit }, message) {
      commit('SET_ALERT', { message: message, type: AlertType.success })
    },

    resetAlert ({ commit }) {
      commit('SET_ALERT', { message: null, type: AlertType.success })
    }
  },
  getters: {
    showAlert (state) {
      return state.alert.message !== null
    },

    logged (state) {
      return state.firebaseToken !== null
    },

    finishAnonymousSchedule (state) {
      return (state.finishAnonymousSchedule !== false && state.firebaseToken !== null) !== false
    },

    isAnonymousAccess (state) {
      return state.config?.organization.anonymousAccessUser
    }

  },
  modules: {
  }
})
