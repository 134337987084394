




import { Vue, Component } from 'vue-property-decorator'
import { mapState } from 'vuex'
import SchedulesComponent from '../components/Schedules.vue'

@Component({
  components: { SchedulesComponent },

  computed: {
    ...mapState(['ready'])
  }
})
export default class Schedules extends Vue { }
