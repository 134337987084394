























import { Vue, Component } from 'vue-property-decorator'
import { mapActions, mapState } from 'vuex'
import firebase, { firebaseMessage } from '@/plugins/firebase'

export type IFormForgot = Vue & { validate: () => boolean }

@Component({
  computed: {
    ...mapState(['showLogin'])
  },
  methods: {
    ...mapActions(['showLoading', 'hideLoading', 'setSucessAlert', 'setErrorAlert'])
  }
})
export default class LoginSignin extends Vue {
  email = ''
  emailRules: Record<symbol, unknown>[] = []
  errorMessage: string | null = null

  currentState!: string
  setSucessAlert!: (arg1: string) => void
  setErrorAlert!: (arg1: string) => void
  showLoading!: () => void
  hideLoading!: () => void

  get showErrorMessage () : boolean {
    return this.errorMessage !== null
  }

  get formForgot () : IFormForgot {
    return this.$refs.formForgot as IFormForgot
  }

  back () : void {
    this.$emit('update:currentState', 'signin')
  }

  submit () : void {
    this.errorMessage = null
    this.emailRules = [
      (v: string) => !!v || 'E-mail é obrigatório',
      (v: string) => /.+@.+\..+/.test(v.trim()) || 'E-mail precisa ser válido'
    ]
    const self = this // eslint-disable-line @typescript-eslint/no-this-alias
    setTimeout(function () {
      if (self.formForgot.validate()) {
        self.showLoading()
        firebase.auth().sendPasswordResetEmail(self.email.trim())
          .then(() => {
            self.setSucessAlert(`Verifique o seu e-mail (${self.email.trim()}) e siga as instruções para recuperar a sua senha.`)
            self.back()
          })
          .catch(error => { self.errorMessage = firebaseMessage(error) })
          .finally(() => self.hideLoading())
      }
    })
  }
}
