export class Base {
  public static fromJson<T> (json: Record<symbol, unknown>) : T {
    return this.assign(new this(), json) as T
  }

  protected static assign (target: Base, ...sources: Record<symbol, unknown>[]) : Base {
    sources.forEach(source => {
      Object.defineProperties(target, Object.keys(source).reduce((descriptors: any, key: any) => { // eslint-disable-line @typescript-eslint/no-explicit-any
        const camelizeKey = this.camelize(key)
        // if (Object.prototype.hasOwnProperty.call(target, camelizeKey)) {
        descriptors[camelizeKey] = Object.getOwnPropertyDescriptor(source, key)
        // }
        return descriptors
      }, {}))
    })
    return target
  }

  protected static camelize (str: string) : string {
    return str.toLowerCase().replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase())
  }
}
