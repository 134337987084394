import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import Home from '../views/Home.vue'
import Schedules from '../views/Schedules.vue'
import SchedulesEdit from '../views/SchedulesEdit.vue'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    component: Home
  },
  {
    path: '/schedules',
    component: Schedules
  },
  {
    path: '/schedules/:id',
    component: SchedulesEdit
  },
  {
    path: '/:salon',
    component: Home
  },
  {
    path: '/:salon/:professional',
    component: Home
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
