import { Configuration, Salon, Service, Professional, Slot, Schedule, Customer, ScheduleTotal } from '../models'
import { AxiosResponse } from 'axios'

declare global {
  interface Window {
    axios: any // eslint-disable-line @typescript-eslint/no-explicit-any
  }
}

class ApiService {
  constructor () {
    const parts = location.hostname.split('.')
    let subdomain = parts.shift()
    if (subdomain === 'localhost' || subdomain === 'webschedule') subdomain = 'lamafia'
    window.axios.defaults.baseURL = process.env.NODE_ENV === 'production' ? `https://${subdomain}.bemp.app/api` : 'http://localhost:3000/api' // 'https://lamafia.bemp.app/api'
  }

  getSettings () : Promise<Configuration> { // webschedule_config
    return window.axios.get('config')
      .then((r: AxiosResponse) => Configuration.fromJson(r.data))
  }

  getSalons () : Promise<Salon[]> {
    return window.axios.get('salons')
      .then((r: AxiosResponse) => r.data.map((m: Record<symbol, unknown>) => Salon.fromJson(m)))
  }

  getServices () : Promise<Service[]> {
    return window.axios.get('services')
      .then((r: AxiosResponse) => r.data.map((m: Record<symbol, unknown>) => Service.fromJson(m)))
  }

  getProfessionals (salon: number) : Promise<Professional[]> {
    return window.axios.get(salon ? `salons/${salon}/professionals` : 'professionals')
      .then((r: AxiosResponse) => r.data.map((m: Record<symbol, unknown>) => Professional.fromJson(m)))
  }

  getScheduleServices (salon: Salon) : Promise<Service[]> {
    return window.axios.get(`salons/${salon.id}/services`)
      .then((r: AxiosResponse) => r.data.map((m: Record<symbol, unknown>) => Service.fromJson(m)))
  }

  getScheduleProfessionals (salon: Salon, service: Service) : Promise<Professional[]> {
    return window.axios.get(`salons/${salon.id}/services/${service.id}/professionals`)
      .then((r: AxiosResponse) => r.data.map((m: Record<symbol, unknown>) => Professional.fromJson(m)))
  }

  getScheduleSlots (salon: Salon, service: Service, professional: Professional | null, date: string) : Promise<Slot[]> {
    const prepend = professional && professional.id && professional.id !== -1 ? `/professionals/${professional.id}` : ''
    return window.axios.get(`salons/${salon.id}/services/${service.id}${prepend}/slots/${date}`)
      .then((r: AxiosResponse) => r.data.map((m: Record<symbol, unknown>) => Slot.fromJson(m)))
  }

  getScheduleTotal (salon: Salon, service: Service, professional: Professional, date: string) : Promise<ScheduleTotal> {
    return window.axios.get('schedules/total', {
      params: {
        salon_id: salon.id,
        service_id: service.id,
        professional_id: professional.id,
        date: date
      }
    }).then((r: AxiosResponse) => ScheduleTotal.fromJson(r.data)).catch(ScheduleTotal.fromJson({}))
  }

  saveSchedule (schedule: Schedule, origin: string) {
    const data = {
      schedule: {
        id: schedule.id,
        start_time: schedule.slot.start,
        end_time: schedule.slot.end,
        user_id: schedule.professional ? schedule.professional.id : null,
        salon_id: schedule.salon.id,
        service_id: schedule.service.id,
        random_professional: schedule.professional && schedule.professional.id === -1,
        origin
      }
    }
    return window.axios.post('schedules', data)
  }

  getSchedules () : Promise<Schedule[]> {
    return window.axios.get('schedules/history')
      .then((r: AxiosResponse) => r.data.map((m: Record<symbol, unknown>) => Schedule.fromJson(m)))
  }

  cancelSchedule (id: number) : Promise<void> {
    return window.axios.put(`schedules/${id}`)
  }

  register (customer: Customer) {
    const data = { customer: customer }
    return window.axios.post('registrations/register', data)
  }

  registrationCheck (firebaseToken: string) : Promise<void> {
    return window.axios.post('/registrations/check', {}, {
      headers: {
        'firebase-token': firebaseToken
      }
    })
  }
}

export default new ApiService()
