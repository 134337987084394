














import { Vue, Component, Prop } from 'vue-property-decorator'
import { Salon } from '../../models'

@Component
export default class SocialLinks extends Vue {
  @Prop() salon: Salon
}
