import Cookies from 'js-cookie'

type QueryStringType = string | (string | null)[]
type SalonType = number | string | undefined

const trackerUrl = (environment: string) => (process.env.NODE_ENV === 'production' && (!environment || environment === 'production')) ? 'https://www.google.com/maps/conversion/collect' : 'https://www.google.com/maps/conversion/debug/collect'

export const setupTracker = (environment: QueryStringType = 'production', token: QueryStringType, salon: SalonType):void => {
  if (token) Cookies.set('rwg', JSON.stringify({ environment, token, salon }), { expires: 30 })
}

export const trackingActive = (): boolean => (
  Cookies.get('rwg') !== undefined
)

export const trackConversion = (salon: SalonType): void => {
  const data = Cookies.get('rwg')

  if (!data) return

  const { environment, token, salon: trackedSalon } = JSON.parse(data)

  const body = JSON.stringify({
    conversion_partner_id: '20000472',
    rwg_token: token,
    merchant_changed: trackedSalon === String(salon) ? '2' : '1'
  })

  fetch(trackerUrl(environment), { method: 'POST', body })

  Cookies.remove('rwg')
}
