


































import { Vue, Component } from 'vue-property-decorator'
import { mapState } from 'vuex'
import LoginComponent from './components/Login.vue'
import AlertComponent from './components/Alert.vue'
import MenuComponent from './components/Menu.vue'
import { Configuration } from './models'

@Component({
  components: { LoginComponent, AlertComponent, MenuComponent },

  computed: {
    ...mapState(['ready', 'loading', 'config', 'embedded', 'transparentBackground'])
  }
})
export default class App extends Vue {
  config!: Configuration
  embedded!: boolean
  transparentBackground!: boolean

  get logo () : null | string {
    return (this.config && this.config.organization) ? this.config.organization.logo : null
  }

  get getEmbedded () : null | string {
    return this.embedded ? 'embedded' : null
  }

  get getTransparentBackground () : null | string {
    return this.transparentBackground ? 'transparent-background' : null
  }
}
