
































import { Vue, Component, Prop } from 'vue-property-decorator'
import { Base, Entity } from '../../models'

@Component
export default class CardItem extends Vue {
  @Prop() useIcon: boolean | false
  @Prop() item?: Entity
  @Prop() fnClick: () => void | undefined

  click (value: Base) : void {
    this.$emit('fnClick', value)
  }

  get gradient () : string {
    return this.$vuetify.theme.dark ? 'to bottom, rgba(0,0,0,0), rgba(0,0,0,1)' : 'to bottom, rgba(255,255,255,0), rgba(255,255,255,0.9)'
  }
}
