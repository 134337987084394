























import { Vue, Component } from 'vue-property-decorator'
import { mapActions, mapState } from 'vuex'
import firebase, { firebaseMessage } from '@/plugins/firebase'

export type IFormAnonymousAccess = Vue & { validate: () => boolean }

@Component({
  methods: {
    ...mapActions(['showLoading', 'hideLoading', 'setAnonymousData', 'setFinishAnonymousSchedule']),
    ...mapState(['finishAnonymousSchedule'])
  }
})
export default class AnonymousLogin extends Vue {
  name = ''
  phone = ''

  phoneRules: Record<symbol, unknown>[] = []
  nameRules: Record<symbol, unknown>[] = []
  errorMessage: string | null = null
  setAnonymousData!: (arg1: string) => void
  setFinishAnonymousSchedule!: (arg1: boolean) => void
  showLoading!: () => void
  hideLoading!: () => void

  get showErrorMessage () : boolean {
    return this.errorMessage !== null
  }

  get formAccessAnonymous () : IFormAnonymousAccess {
    return this.$refs.formAccessAnonymous as IFormAnonymousAccess
  }

  submit () : void {
    this.nameRules = [
      (v: string):string|boolean => (v && v.length >= 3) || 'Nome é obrigatório' // ,
    ]
    this.phoneRules = [
      (v: string):string|boolean => !!v || 'Telefone é obrigatório',
      (v: string):string|boolean => (v && v.length >= 14) || 'Informe um telefone válido'
    ]
    const self = this // eslint-disable-line @typescript-eslint/no-this-alias
    setTimeout(function () {
      if (self.formAccessAnonymous.validate()) {
        self.showLoading()
        self.setAnonymousData(new URLSearchParams({ name: self.name, phone: self.phone }).toString())
        setTimeout(function () {
          firebase.auth().signInAnonymously()
            .then(() => {
              self.setFinishAnonymousSchedule(true)
            })
            .catch(error => {
              self.errorMessage = firebaseMessage(error)
            })
            .finally(() => self.hideLoading())
        })
      }
    })
  }
}
