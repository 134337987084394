





















import { IAlert, AlertType } from '@/models'
import { Vue, Component } from 'vue-property-decorator'
import { mapActions, mapGetters, mapState } from 'vuex'

@Component({
  computed: {
    ...mapState(['alert']),
    ...mapGetters(['showAlert'])
  },
  methods: {
    ...mapActions(['resetAlert'])
  }
})
export default class AlertComponent extends Vue {
  alert!: IAlert
  showAlert!: boolean

  get color () : AlertType {
    return this.alert.type
  }

  get icon () : string {
    return (this.alert.type === AlertType.success) ? 'fas fa-check' : 'fas fa-times'
  }
}
