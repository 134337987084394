'use strict'

import Vue from 'vue'
import axios from 'axios'
import store from '../store'

axios.defaults.baseURL = process.env.baseURL || process.env.apiUrl || ''
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded'

const config = {
  // baseURL: process.env.baseURL || process.env.apiUrl || ""
  // timeout: 60 * 1000, // Timeout
  // withCredentials: true, // Check cross-site Access-Control
}

const _axios = axios.create(config)

_axios.interceptors.request.use(
  function (config) {
    if (store.state.firebaseToken) config.headers['firebase-token'] = store.state.firebaseToken

    if (store.state.anonymousData) {
      if (config.url === 'schedules') {
        config.headers['X-User-Data'] = store.state.anonymousData // create one item in header request for anonymous user access
      } else {
        config.headers['firebase-token'] = ''
      }
    }

    return config
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error)
  }
)

// Add a response interceptor
_axios.interceptors.response.use(
  function (response) {
    return response
  },
  function (error) {
    return Promise.reject(error)
  }
)

Plugin.install = function (Vue) {
  Vue.axios = _axios
  window.axios = _axios
  Object.defineProperties(Vue.prototype, {
    axios: {
      get () {
        return _axios
      }
    },
    $axios: {
      get () {
        return _axios
      }
    }
  })
}

Vue.use(Plugin)

export default Plugin
