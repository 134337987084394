




import { Vue, Component } from 'vue-property-decorator'
import { mapState } from 'vuex'
import ScheduleComponent from '../components/Schedule.vue'

@Component({
  components: { ScheduleComponent },

  computed: {
    ...mapState(['ready'])
  }
})
export default class Home extends Vue { }
