import { Entity } from './'

export class SocialLinks extends Entity {
  facebook?: string;
  instagram?: string;
  threads?: string;
  twitter?: string;
  linkedin?: string;
  youtube?: string;
  tiktok?: string;
  pinterest?: string;
  website?: string;
}

export class Salon extends Entity {
  remoteGrouping = ''
  thumbnail = ''
  slug = ''
  address = ''
  socialLinks?: SocialLinks

  get cardDescription () : string {
    return this.address
  }

  public static fromJson (json: Record<string, any>) : any { // eslint-disable-line @typescript-eslint/no-explicit-any
    const salon = super.fromJson(json) as Salon
    if (json.socialLinks) {
      salon.socialLinks = SocialLinks.fromJson(json.socialLinks)
    }
    return salon
  }
}
