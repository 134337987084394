














import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { Schedule, ScheduleTotal } from '../../models'
import ApiService from '../../api'
import { mapGetters } from 'vuex'

@Component({
  computed: {
    ...mapGetters(['logged'])
  }
})
export default class SchedulesTotal extends Vue {
  @Prop() schedule: Schedule | undefined
  @Prop() date: string | undefined
  total: ScheduleTotal | undefined = new ScheduleTotal()
  logged!: boolean

  @Watch('date', { immediate: true, deep: true })
  onDateChange (to: string) : void {
    if (to) this.loadTotal()
  }

  @Watch('logged', { immediate: true, deep: true })
  onLoggedChange () : void {
    this.loadTotal()
  }

  loadTotal () : void {
    if (this.schedule && this.date) {
      ApiService.getScheduleTotal(this.schedule.salon, this.schedule.service, this.schedule.professional, this.date)
        .then((total: ScheduleTotal) => {
          this.total = total
        })
    }
  }
}
