import { Base } from '.'

export class ConfigurarionOrganization extends Base {
  id: number
  subdomain: string
  name: string
  logo: string
  firebaseConfig: Record<string, unknown>
  anonymousAccessUser: boolean
}

export class ConfigurarionFeatures extends Base {
  rewards: boolean
  packages: boolean
  suppressProfessional: boolean
  randomProfessionalScheduling: boolean
  forwardDays: number
  groupByCountry: boolean
  lateLogin: boolean
  mandatoryLogin: boolean
  multipleSalons: boolean
  multipleRegions: boolean
  webschedule: boolean
}

export class ConfigurarionColors extends Base {
  darkMode: boolean
  primary: string
  secondary: string
}

export class Configuration extends Base {
  organization: ConfigurarionOrganization
  features: ConfigurarionFeatures
  colors: ConfigurarionColors

  constructor (organization: ConfigurarionOrganization, features: ConfigurarionFeatures, colors: ConfigurarionColors) {
    super()
    this.organization = organization
    this.features = features
    this.colors = colors
  }

  public static fromJson (json: Record<string, any>) : any { // eslint-disable-line @typescript-eslint/no-explicit-any
    return new Configuration(
      ConfigurarionOrganization.fromJson(json.organization) as ConfigurarionOrganization,
      ConfigurarionFeatures.fromJson(json.features) as ConfigurarionFeatures,
      ConfigurarionColors.fromJson(json.colors) as ConfigurarionColors)
  }
}
