












import { Vue, Component, Prop } from 'vue-property-decorator'
import { Schedule, Service } from '../../models'
import ScheduleCard from '../card/card.vue'
import ApiService from '../../api'

@Component({
  components: { ScheduleCard }
})
export default class ScheduleService extends Vue {
  @Prop() schedule: Schedule | undefined
  @Prop() urlService: string | null

  services: Array<Service> = []
  loading = true

  updateParent (value: Service | null) : void {
    this.$emit('update:service', value)
  }

  beforeMount () : void {
    this.loading = true
    this.schedule && ApiService.getScheduleServices(this.schedule.salon).then((s: Array<Service>) => {
      this.services = s
      if (this.urlService !== null) this.fetchUrlService()
      this.loading = false
    })
  }

  fetchUrlService () : void {
    const service = this.services.find(s => s.id?.toString() === this.urlService)
    if (service) {
      this.loading = false
      this.updateParent(service)
    }
  }
}
