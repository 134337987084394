import { Configuration } from '../'

export enum AlertType {
  success = 'success',
  error = 'error'
}
export interface IAlert {
  message: null | string,
  type: AlertType
}
export interface IState {
  config: null | Configuration,
  customer: null | string,
  firebaseToken: null | string,
  anonymousData: null | string,
  finishAnonymousSchedule: boolean,
  alert: IAlert,
  loading: boolean,
  showLogin: boolean,
  ready: boolean,
  embedded: boolean,
  transparentBackground: boolean
}
