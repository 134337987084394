



















import { Vue, Component, Watch } from 'vue-property-decorator'
import { mapState, mapGetters } from 'vuex'

import LoginSignin from './login/signin.vue'
import LoginForgot from './login/forgot.vue'
import LoginSignup from './login/signup.vue'
import AnonymousLogin from './login/anonymousLogin.vue'
import { Configuration } from '@/models'

@Component({
  components: { LoginSignin, LoginForgot, LoginSignup, AnonymousLogin },

  computed: {
    ...mapState(['showLogin', 'config']),
    ...mapGetters(['isAnonymousAccess'])
  }
})
export default class LoginComponent extends Vue {
  config: Configuration
  showLogin!: boolean
  isAnonymousAccess!: boolean
  isAnonymous: boolean
  currentState = 'signin'
  @Watch('showLogin', { immediate: true, deep: true })
  onShowLoginChange (to: boolean) : void {
    if (this.config && this.isAnonymousAccess) {
      this.currentState = 'withoutRegister'
    } else if (to === false) {
      this.currentState = 'signin'
    }
  }

  get showModal () : boolean {
    return this.showLogin
  }
}
