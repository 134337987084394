












import { Vue, Component, Prop } from 'vue-property-decorator'
import { Schedule, Professional } from '../../models'
import ScheduleCard from '../card/card.vue'
import ApiService from '../../api'

@Component({
  components: { ScheduleCard }
})
export default class ScheduleProfessional extends Vue {
  @Prop() schedule: Schedule | undefined
  @Prop() urlProfessional: string | null
  @Prop() randomProfessional: boolean

  professionals: Professional[] = []
  loading = true

  updateParent (value: Professional | null) : void {
    this.$emit('update:professional', value)
  }

  beforeMount () : void {
    this.loading = true
    this.schedule && ApiService.getScheduleProfessionals(this.schedule.salon, this.schedule.service).then((s: Array<Professional>) => {
      if (this.randomProfessional) {
        const emptyProfessional = {
          id: -1,
          name: 'Sem preferência',
          description: 'Abxd',
          grade: null,
          image: 'https://bemp-public.s3.amazonaws.com/app/any-professional.png',
          useIcon: true,
          icon: 'fas fa-user',
          salons: [],
          services: [],
          slug: '',
          thumbnail: 'https://bemp-public.s3.amazonaws.com/app/any-professional.png',
          cardDescription: null
        }
        this.professionals = [emptyProfessional, ...s]
      } else {
        this.professionals = s
      }
      if (this.urlProfessional !== null) this.fetchUrlProfessional()
      this.loading = false
    })
  }

  fetchUrlProfessional () : void {
    const professional = this.professionals.find(s => s.slug === this.urlProfessional)
    if (professional) {
      this.loading = false
      this.updateParent(professional)
    }
  }
}
