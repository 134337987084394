import { Entity } from './commom/entity'

export class Service extends Entity {
  price = ''

  public static fromJson (json: Record<string, any>) : any { // eslint-disable-line @typescript-eslint/no-explicit-any
    const s = super.fromJson(json) as any // eslint-disable-line @typescript-eslint/no-explicit-any
    if (s.prices) s.price = s.prices[0].value_currency
    return s as Service
  }

  get cardDescription () : string {
    return this.price
  }
}
