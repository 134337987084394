














































import { Vue, Component, Prop } from 'vue-property-decorator'
import { Base, Salon } from '../../models'
import CardItem from './card-item.vue'

@Component({
  components: { CardItem }
})
export default class ScheduleCard extends Vue {
  @Prop() icon: string | undefined
  @Prop() title: string | undefined
  @Prop() subtitle: string | undefined

  @Prop() loading: boolean | undefined

  @Prop() items: Base[] | undefined
  @Prop() item: Base | undefined

  @Prop() fnReset: void | undefined
  @Prop() fnClick: void | undefined

  click (value: Base) : void {
    this.$emit('fnClick', value)
  }

  reset () : void {
    this.$emit('fnReset')
  }

  get description () : string | null {
    if (this.item instanceof Salon) {
      return this.item.cardDescription
    }
    return null
  }

  get hasSlot () : boolean {
    return !!this.$slots.default
  }
}
