import { Base } from '.'
import moment from '../plugins/moment'
export class Slot extends Base {
  start: moment.Moment
  end: moment.Moment
  startText: string
  endText: string
  starttext: string // Workaround to get the id

  constructor (slot: Slot | undefined = undefined) {
    super()
    if (slot) {
      this.start = slot.start
      this.end = slot.end
      this.startText = slot.startText
      this.endText = slot.endText
    }
  }

  get id () : string {
    return this.startText || this.starttext
  }

  get name () : string {
    return moment(this.start).format('dddd[,] DD/MM/YYYY [às] ') + this.id
  }
}
