




import { Vue, Component } from 'vue-property-decorator'
import { mapActions, mapGetters, mapState } from 'vuex'
import ScheduleComponent from '../components/Schedule.vue'

import { Schedule } from '@/models'
import ApiService from '../api'

@Component({
  components: { ScheduleComponent },

  computed: {
    ...mapState(['ready', 'firebaseToken']),
    ...mapGetters(['logged'])
  },

  methods: {
    ...mapActions(['showLoading', 'hideLoading', 'setSucessAlert', 'setErrorAlert', 'checkNeedLogin'])
  }
})
export default class SchedulesEdit extends Vue {
  schedule: Schedule = new Schedule()

  ready!: boolean
  logged!: boolean
  firebaseToken!: string
  showLoading!: () => void
  hideLoading!: () => void
  checkNeedLogin!: (arg1:boolean) => void

  get hasLogin () : boolean {
    if (this.logged) {
      this.load()
      return true
    } else {
      this.checkNeedLogin(true)
      return false
    }
  }

  load () : void {
    this.showLoading()
    ApiService.getSchedules().then((s: Schedule[]) => {
      this.schedule = s.find(s => s.id === parseInt(this.$route.params.id)) as Schedule
      this.hideLoading()
    })
  }
}
