import { Base } from '../'

export abstract class Entity extends Base {
  id: number | string | undefined
  name: string | undefined

  get cardDescription (): string | null {
    return null
  }
}
